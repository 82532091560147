// testing for board
import Vue from 'vue'
import Vuex from 'vuex'
import {
  getLastSpecificDocumentsApi,
  getLastDocumentApi,
  getFactoriesInfoApi,
  getAllGroupsApi,
  getAllAlarmsApi,
  getSortedFactoriesApi,
  getOtherSystemFactoriesApi
} from '../api'
import router from '../router/index'
import siteInfoDetail from './modules/siteInfoDetail'
import deviceStatus from './modules/deviceStatus'
import deviceHistory from './modules/deviceHistory'
import ChartsAndBarItem from './modules/ChartsAndBarItem'
import pvStringFailure from './modules/pvStringFailure'
import calculate from '../utils/calculate'
import { removeDuplicates } from '../utils/removeDuplicates'
import renderTableData from './function/deviceStatus/tableRender'
import {
  dbDataHandle,
  updateFactoryData,
  handleIRRArray,
  decryptData
} from './function/common/index'
import Highcharts from 'highcharts'
import { DateTime } from 'luxon'

// ----- chart theme -----
const chartTheme = {
  lang: {
    thousandsSep: ',' // 千分位符號
  }
}
Highcharts.setOptions(chartTheme)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    minDataLastTime: '',
    navbarShow: true,
    globalFactory: '',
    roleAdmin: ['Whole_Admin', 'OM_Head', 'Admin'],
    dataAnalysisChooseTimeAdmin: ['Whole_Admin', 'OM_Head', 'Admin', '工務'],
    dataAdmin: ['Whole_Admin', 'Admin'],
    realTimeDataReload: true,
    userDetail: {
      account: '',
      displayName: '',
      factoriesList: [''],
      demoList: [''],
      id: '',
      role: ''
    },
    hasRoleAdmin: false, // 可看 roleAdmin 權限資料
    hasDataAnalysisTimePermission: false, // 可看 dataanalysis '時' 資料
    hasFactoryPermission: false, // 可看案場
    hasDemoPermission: false, // 可看 demo
    querySiteName: '', // call api 時的 site name
    demoContent: [
      '(展示案場)',
      '2020-01-01',
      '12345678',
      '東西向',
      '北區',
      '台灣',
      '桃園市',
      '桃園區',
      'ABCD_0123456',
      '1234 Pcs',
      '30°',
      '外線高壓併，3相3線 11.4kV',
      '桃園市桃園區',
      '進金生能源服務',
      '121.074844',
      '23.927734',
      '8',
      '2020-01-01'
    ],
    factoryData: [],
    factoryInfo: [],
    hasGotForeignList: false,
    foreignFactoryList: [],
    sortedFactoryNameList: [],
    alarmAmountData: {},
    factoryError: [],
    capacity: [], // 發電時數: acp / cap
    alertData: [],
    overlayShow: true,
    appQuery: {},
    siteinfoNum: 0,
    sideNum: 1,
    timer: '',
    showMarquee: false,
    isNewData: true,
    dark: false,
    tableThColor: 'light',
    admin: false,
    fid: '',
    lastMonAcpIrr: {},
    msalInstance: null,
    currentName: '',
    realTimeRePostBeginTime: null,
    ownerArrLength: '',
    roleArray: [],
    rolePermissionArray: [],
    roleComponentMenu: [],
    roleOthersMenu: [],
    visibilityArray: [],
    filterSiteArray: [
      {
        name: '不篩選',
        value: []
      },
      {
        name: '北區',
        value: ['花蓮縣', '宜蘭縣', '台北市', '桃園市', '新竹縣']
      },
      {
        name: '中區',
        value: ['苗栗縣', '台中市', '彰化縣', '南投縣', '雲林縣']
      },
      {
        name: '南區',
        value: [
          '嘉義縣',
          '台南市',
          '高雄市',
          '屏東縣',
          '台東市',
          '金門縣',
          '澎湖縣'
        ]
      }
    ],
    invAckwhRetention: {
      switchInverterIndex: [],
      initAckwhTs: [[]],
      initAckwhValue: [[]]
    }
  },
  getters: {
    // realtime factoryNameList options
    getFactoryInfoNameList (state) {
      return state.factoryInfo.map((item) => item.displaySiteName)
    }
  },
  mutations: {
    ADD_USER_DETAIL (state, payload) {
      state.userDetail = payload

      // 可觀看案場
      state.hasFactoryPermission = state.userDetail.factoriesList?.[0] !== '無'

      // 可觀看 demo
      state.hasDemoPermission = state.userDetail.demoList?.[0] !== '無'

      // 可看告警 等等權限
      state.hasRoleAdmin = state.roleAdmin.includes(state.userDetail.role)

      // 可看資料分析頁 選擇、查看 '時' 資料
      state.hasDataAnalysisTimePermission =
        state.dataAnalysisChooseTimeAdmin.includes(state.userDetail.role) ||
        (state.userDetail.account === 'yunbao' &&
          state.userDetail.displayName === '雲豹能源')
      // 開放雲豹可觀看
    },
    CHANGE_GOT_FOREIGN_FACTORY_STATE (state, payload) {
      state.hasGotForeignList = payload
    },
    ADD_FOREIGN_FACTORY_LIST (state, payload) {
      state.foreignFactoryList = payload
    },
    ADD_SORTED_FACTORY_NAME_LIST (state, payload) {
      state.sortedFactoryNameList = payload
    },
    ADD_FACTORY_INFO (state, payload) {
      // all data
      state.factoryInfo = payload

      const factoryInfoKeyArray = [
        'factoryAppName',
        'systemStartupDate',
        'powerStartupDate',
        'projectNumber',
        'caseOrientation',
        'caseArea',
        'country',
        'county',
        'district',
        'moduleType',
        'moduleCounts',
        'moduleBevel',
        'highVoltageParallel',
        'caseSite',
        'contactPerson',
        'longitude',
        'latitude',
        'unitPrice',
        'warrantyStartDate',
        'warrantyDueDate',
        'presetIrrModule',
        'invModule',
        'caseCapacity',
        'caseCapacityInt',
        'moduleOrientation',
        'omPrincipal',
        'omNumber',
        'acmeStatus',
        'powerPlantTag',
        'ipCam'
      ]

      state.siteInfoDetail.groupNumber = 0

      for (const val of payload) {
        if (state.querySiteName === val.factory) {
          const infoData = val.informations

          const keysLengthNotEqualDataLength =
            Object.keys(infoData)?.length !== factoryInfoKeyArray.length
          if (keysLengthNotEqualDataLength) {
            // 將資料補足 ex: data 可能沒有 powerStartupDate
            factoryInfoKeyArray.forEach((x) => {
              if (!infoData[x]) {
                infoData[x] = ''
              }
            })
          }

          const informationsArr = Object.entries(infoData).sort(
            (a, b) =>
              factoryInfoKeyArray.indexOf(a[0]) -
              factoryInfoKeyArray.indexOf(b[0])
          )

          state.siteInfoDetail.siteInfoValue =
            Object.fromEntries(informationsArr)

          state.siteInfoDetail.weatherLonAndLat.lon =
            state.siteInfoDetail.siteInfoValue.longitude

          state.siteInfoDetail.weatherLonAndLat.lat =
            state.siteInfoDetail.siteInfoValue.latitude

          state.siteInfoDetail.uluru.lng =
            state.siteInfoDetail.siteInfoValue.longitude

          state.siteInfoDetail.uluru.lat =
            state.siteInfoDetail.siteInfoValue.latitude

          if (val.linkGroupNumber) {
            state.siteInfoDetail.groupNumber = val.linkGroupNumber
          }

          state.siteInfoDetail.ipCamNum = val.ipCamNum
        }
      }
    },
    ADD_FACTORY_DATA (state, payload) {
      const hasDemo = payload.find((item) => item.isDemoSite)

      if (hasDemo) {
        let demoList = []
        let factoryList = []
        state.factoryData = state.factoryData.concat(payload)

        state.factoryData.forEach((item) => {
          if (item.isDemoSite) return demoList.push(item)
          if (!item.isDemoSite) return factoryList.push(item)
        })
        demoList = demoList.sort(
          (a, b) =>
            a.displaySiteName.split(' ')[1] - b.displaySiteName.split(' ')[1]
        )

        state.factoryData = [...demoList, ...factoryList]
      }

      if (!hasDemo) {
        // 濾除 undefined
        state.factoryData = state.factoryData.concat(payload).filter((i) => i)
      }

      // filter repeat
      state.factoryData = removeDuplicates(
        state.factoryData,
        'displaySiteName'
      )
    },
    ADD_QUERY_SITE_NAME (state, payload) {
      state.querySiteName = payload

      const hasDemo = payload?.includes('展示案場')
      if (hasDemo) {
        const demoIndex = payload.split(' ')[1] - 1
        state.querySiteName = state.factoryInfo[demoIndex].factory
      }
    },
    UPDATE_DATA_ANALYSIS_STATE (state) {
      state.factoryData.forEach((item) => {
        if (state.querySiteName === item.factoryName) {
          state.capacity = item.capacity
          state.siteInfoDetail.capacity = item.capacity
        }
      })
    },
    SET_INV_ACKWH_RETENTION (state, payload) {
      state.invAckwhRetention = payload || {
        switchInverterIndex: [],
        initAckwhTs: [[]],
        initAckwhValue: [[]]
      }
    },
    CHANGE_DARK_MODE (state) {
      state.dark = true
      state.tableThColor = 'dark'
    },
    CHANGE_LIGHT_MODE (state) {
      state.dark = false
      state.tableThColor = 'light'
    },
    CHANGE_REALTIME_RELOAD (state, payload) {
      state.realTimeDataReload = payload
    },
    LAST_MON_ACP_IRR (state, payload) {
      state.lastMonAcpIrr = payload
    },
    ASSIGN_MSAL (state, payload) {
      state.msalInstance = payload
    },
    RESET_FACTORY_DATA (state) {
      state.factoryData = []
    },
    ROLE_ARRAY (state, payload) {
      state.roleArray = payload.map((obj) => {
        if (!obj.othersMenu) obj.othersMenu = []
        return obj
      })
    },
    UPDATE_ROLE_ARRAY (state) {
      const roleMenu = state.roleArray.find(
        (x) => x.role === state.userDetail.role
      )
      if (roleMenu) {
        state.roleComponentMenu = roleMenu.componentMenu
        state.roleOthersMenu = roleMenu.othersMenu
        state.rolePermissionArray = roleMenu.componentMenu.concat(
          roleMenu.sideMenu
        )
      } else {
        Vue.swal('無此權限')
        state.roleComponentMenu = []
        state.rolePermissionArray = []
        state.roleOthersMenu = []
      }
    },
    ADD_ALARM_DATA (state, payload) {
      state.alarmAmountData = payload
    }
  },
  actions: {
    setTelemetryData ({ state, commit, dispatch }, payload) {
      // from signalMessage, 需要每分鐘更新的資料都塞這
      const { deviceStatus } = state

      if (
        state.querySiteName === payload.factoryName &&
        payload.detail.SYSTIME > state.minDataLastTime
      ) {
        // 時間
        deviceStatus.dataTime = DateTime.fromSeconds(
          Number(payload.detail.SYSTIME)
        ).toFormat('yyyy-MM-dd HH:mm')

        // 圖表series.data
        commit('deviceStatus/CLEAR_CHART_X_AXIS')
        dispatch('deviceStatus/setXAxisCategories')

        // AC_kWh 每個加上換機保留發電量值 (websocket 的 AC_kWh 還沒加上換機保留發電量)
        const hasInvAckwhRetention =
          state.invAckwhRetention.switchInverterIndex?.length
        if (hasInvAckwhRetention) {
          // console.log('AC_kWh 加上換機保留發電量值')
          for (
            let i = 0;
            i < state.invAckwhRetention.switchInverterIndex.length;
            i++
          ) {
            const invIdx = state.invAckwhRetention.switchInverterIndex[i]
            const invValueAry = state.invAckwhRetention.initAckwhValue[i]
            payload.detail.AC_kWh[invIdx] =
              Math.round(
                (payload.detail.AC_kWh[invIdx] +
                  invValueAry[invValueAry.length - 1]) *
                  100
              ) / 100
          }
        }

        const deviceStatusSeriesArray = [
          payload.detail.AC_kWh,
          payload.detail.E_today,
          payload.detail.acp,
          payload.detail.dcp
        ]
        deviceStatusSeriesArray.forEach((_, idx) => {
          deviceStatus.deviceStatusChartOptions.series[idx].data =
            deviceStatusSeriesArray[idx]
        })
        // 圖表 Ymax setting
        if (
          payload.detail.AC_kWh &&
          payload.detail.E_today &&
          payload.detail.acp
        ) {
          // AC_kWh
          deviceStatus.deviceStatusChartOptions.yAxis[0].max =
            Math.max(...payload.detail.AC_kWh) * 1.1

          // E_today
          deviceStatus.deviceStatusChartOptions.yAxis[1].max =
            Math.max(...payload.detail.E_today) * 2

          // acp、dcp
          deviceStatus.deviceStatusChartOptions.yAxis[2].max =
            Math.max(...payload.detail.E_today) * 2
        }
        // 累積發電 & 今日發電
        // AC_kWh 已判斷是否加上換機保留發電量值 所以直接相加=累積發電
        state.siteInfoDetail.voltages.accVal = dbDataHandle(
          calculate.sumValue(payload.detail.AC_kWh)
        )
        state.siteInfoDetail.voltages.todayVal = dbDataHandle(
          calculate.sumValue(payload.detail.E_today)
        )
        // 即時資訊
        state.siteInfoDetail.realTimeGrid.IRR = dbDataHandle(
          handleIRRArray(payload.detail.IRR) * 1000,
          0
        )
        state.siteInfoDetail.realTimeGrid.moduleTemp = dbDataHandle(
          payload.detail.PVTemp
        )
        try {
          state.siteInfoDetail.realTimeGrid.lowCurrent = dbDataHandle(
            payload.detail.etc['LV-meter'].P
          )
        } catch (err) {
          state.siteInfoDetail.realTimeGrid.lowCurrent = '-'
          // console.log('lowCurrent uncaught error', err)
        }
        try {
          state.siteInfoDetail.realTimeGrid.highCurrent = dbDataHandle(
            payload.detail.etc['HV-meter'].P
          )
        } catch (err) {
          state.siteInfoDetail.realTimeGrid.highCurrent = '-'
          // console.log('highCurrent uncaught error', err)
        }
        // deviceStatus table Data
        deviceStatus.tableData = renderTableData(payload.detail)
        state.isNewData = !state.isNewData
        state.showMarquee = !state.showMarquee
      }

      // 恢復連線更新畫面
      state.factoryData.forEach((item) => {
        try {
          if (item.factoryName && item.factoryName === payload.factoryName) {
            updateFactoryData(
              item,
              payload,
              'IRR',
              'PVTemp',
              'Eff',
              'acp',
              'dcp',
              'E_today',
              'ErrorMessage',
              'dataTimeFromNow'
            )
          }
        } catch (error) {}
      })
    },
    updateSiteInfoDetailState ({ state, commit }) {
      const { siteInfoDetail } = state

      state.factoryData.forEach((item) => {
        if (state.querySiteName === item?.factoryName) {
          state.minDataLastTime = item.SYSTIME

          // setTelemetryData 會判斷是否有 invAckwhRetention 值，否則要 AC_kWh 加上換機保留發電量值
          const findInvAckwhRetention = state.factoryInfo.find(
            (f) => f.factory === state.querySiteName
          ).invAckwhRetention
          commit('SET_INV_ACKWH_RETENTION', findInvAckwhRetention)

          // 累積發電 & 今日發電
          siteInfoDetail.voltages.accVal = dbDataHandle(
            calculate.sumValue(item.AC_kWh)
          )
          siteInfoDetail.voltages.todayVal = dbDataHandle(
            calculate.sumValue(item.E_today)
          )
          siteInfoDetail.realTimeGrid.moduleTemp = dbDataHandle(item.PVTemp)
          // 即時資訊
          siteInfoDetail.realTimeGrid.IRR = dbDataHandle(
            handleIRRArray(item.IRR) * 1000,
            0
          )

          try {
            siteInfoDetail.realTimeGrid.eToday = dbDataHandle(
              state.lastMonAcpIrr.IRR
            )
            siteInfoDetail.realTimeGrid.peak = dbDataHandle(
              state.lastMonAcpIrr.ACP / state.capacity.reduce((a, b) => a + b)
            )
            // siteInfoDetail.realTimeGrid.pr = calculatePR(state.capacity, state.lastMonAcpIrr.ACP, state.lastMonAcpIrr.IRR)
            siteInfoDetail.realTimeGrid.pr = dbDataHandle(
              state.lastMonAcpIrr.PR * 100,
              1
            )
          } catch {
            siteInfoDetail.realTimeGrid.eToday = '-'
            siteInfoDetail.realTimeGrid.peak = '-'
            siteInfoDetail.realTimeGrid.pr = '-'
          }

          try {
            siteInfoDetail.realTimeGrid.lowCurrent = dbDataHandle(
              item.etc['LV-meter'].P
            )
          } catch (err) {
            siteInfoDetail.realTimeGrid.lowCurrent = '-'
            // console.log('lowCurrent uncaught error', err)
          }

          try {
            siteInfoDetail.realTimeGrid.highCurrent = dbDataHandle(
              item.etc['HV-meter'].P
            )
          } catch (err) {
            siteInfoDetail.realTimeGrid.highCurrent = '-'
            // console.log('highCurrent uncaught error', err)
          }
        }
        state.showMarquee = !state.showMarquee
      })
    },
    updateDevicesStatusState ({ state, commit, dispatch }) {
      // 進入畫面時要觸發更新 state
      const { deviceStatus } = state

      if (state.factoryData) {
        state.factoryData.forEach((item) => {
          if (state.querySiteName === item?.factoryName) {
            // time
            deviceStatus.dataTime = DateTime.fromSeconds(
              Number(item.SYSTIME)
            ).toFormat('yyyy-MM-dd HH:mm')

            // 圖表series.data
            commit('deviceStatus/CLEAR_CHART_X_AXIS')
            dispatch('deviceStatus/setXAxisCategories')

            // setTelemetryData 會判斷是否有 invAckwhRetention 值，否則要 AC_kWh 加上換機保留發電量值
            const findInvAckwhRetention = state.factoryInfo.find(
              (f) => f.factory === state.querySiteName
            ).invAckwhRetention
            commit('SET_INV_ACKWH_RETENTION', findInvAckwhRetention)

            // factoryData 獲得 getLastDocumentApi 的 AC_kWh 已有加上換機保留發電量值
            // 所以直接相加=累積發電
            item.AC_kWh = item.AC_kWh.map((v) => Math.round(v * 10) / 10)

            const deviceStatusSeriesArray = [
              item.AC_kWh,
              item.E_today,
              item.acp,
              item.dcp
            ]
            deviceStatusSeriesArray.forEach((_, idx) => {
              deviceStatus.deviceStatusChartOptions.series[idx].data =
                deviceStatusSeriesArray[idx]
            })
            // table
            deviceStatus.tableData = renderTableData(item)
            // 圖表 Ymax setting
            if (item.AC_kWh && item.E_today && item.acp) {
              // AC_kWh
              deviceStatus.deviceStatusChartOptions.yAxis[0].max =
                Math.max(...item.AC_kWh) * 1.1

              // E_today
              deviceStatus.deviceStatusChartOptions.yAxis[1].max =
                Math.max(...item.E_today) * 2

              // acp、dcp
              deviceStatus.deviceStatusChartOptions.yAxis[2].max =
                Math.max(...item.E_today) * 2
            }
            state.showMarquee = !state.showMarquee
          }
        })
      }
    },
    // 外來監控 list
    async asyncForeignFactoryList ({ commit }) {
      try {
        const apiResult = await getOtherSystemFactoriesApi()
        if (apiResult?.data?.data?.length) {
          const list = apiResult.data.data.sort(
            (a, b) =>
              Number(a.factoryId.slice(1)) - Number(b.factoryId.slice(1))
          )
          commit('ADD_FOREIGN_FACTORY_LIST', list)
        }
      } catch (error) {
        console.log('asyncForeignFactoryList', error)
      } finally {
        commit('CHANGE_GOT_FOREIGN_FACTORY_STATE', true)
      }
    },
    // 案場名稱排序 list
    async asyncSortedFactories ({ commit }) {
      try {
        const apiResult = await getSortedFactoriesApi()
        if (apiResult?.data?.data?.length) {
          const apiRes = apiResult.data.data
          const sortedList = decryptData(apiRes)

          commit('ADD_SORTED_FACTORY_NAME_LIST', sortedList)
        }
      } catch (error) {
        console.log('asyncSortedFactories', error)
      }
    },
    // 所有可觀看的資料 demo or factory 的 [ info ]
    async asyncInfo ({ state, commit, dispatch }) {
      if (state.realTimeDataReload) state.overlayShow = true

      // --- demo、factory 可能重複 ---
      function queryFactoryList () {
        // has 'ALL'
        if (state.userDetail.factoriesList?.[0] === 'ALL') return ['ALL']

        // only demo
        if (!state.hasFactoryPermission && state.hasDemoPermission) {
          return state.userDetail.demoList
        }

        // only factory
        if (state.hasFactoryPermission && !state.hasDemoPermission) {
          return state.userDetail.factoriesList
        }

        // not 'ALL', has demo & factory
        const queryList = [
          ...state.userDetail.demoList,
          ...state.userDetail.factoriesList
        ]
        return [...new Set(queryList)]
      }

      // check sorted factory name list
      if (!state.sortedFactoryNameList?.length) {
        await dispatch('asyncSortedFactories')
      }
      const sortedList = state.sortedFactoryNameList

      try {
        const factoriesInfo = await getFactoriesInfoApi(queryFactoryList())

        if (factoriesInfo?.data?.data?.length) {
          const resultData = factoriesInfo.data.data.filter(
            (item) => !item.isArchived
          )

          let sortedInfo = []

          // has demo & factory
          // demo 排前面、兩者各自排序
          if (state.hasFactoryPermission && state.hasDemoPermission) {
            let sortedDemoList = []
            let sortedFactoriesList = []

            resultData.forEach((item) => {
              if (state.userDetail.demoList.includes(item.factory)) {
                sortedDemoList.push(item)
              }
              if (state.userDetail.factoriesList?.[0] === 'ALL') {
                return sortedFactoriesList.push(item)
              }
              if (state.userDetail.factoriesList.includes(item.factory)) {
                sortedFactoriesList.push(item)
              }
            })

            sortedDemoList = sortedDemoList
              .sort((a, b) => a.queue - b.queue)
              .map((item, idx) => {
                let demo = Object.assign({}, item)
                demo.isDemoSite = true
                demo.displaySiteName = `展示案場 ${idx + 1}`
                return demo
              })

            sortedFactoriesList = sortedFactoriesList
              .sort(
                (a, b) =>
                  sortedList.indexOf(a.factory) - sortedList.indexOf(b.factory)
              )
              .map((item) => {
                let data = Object.assign({}, item)
                data.displaySiteName = item.factory
                return data
              })

            sortedInfo = [...sortedDemoList, ...sortedFactoriesList]
          }

          // has only demo
          if (!state.hasFactoryPermission && state.hasDemoPermission) {
            sortedInfo = resultData
              .sort((x, y) => x.queue - y.queue)
              .map((item, idx) => {
                let demo = Object.assign({}, item)
                demo.isDemoSite = true
                demo.displaySiteName = `展示案場 ${idx + 1}`
                return demo
              })
          }

          // has only factory
          if (state.hasFactoryPermission && !state.hasDemoPermission) {
            sortedInfo = resultData
              .sort(
                (a, b) =>
                  sortedList.indexOf(a.factory) - sortedList.indexOf(b.factory)
              )
              .map((item) => {
                let data = Object.assign({}, item)
                data.displaySiteName = item.factory
                return data
              })
          }

          commit('ADD_FACTORY_INFO', sortedInfo)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 所有可觀看的資料中，要展示於 realtime 的 [ data ]
    // 一開始只顯示(call api) 10 筆(?)，往下 scroll 才顯示其他的
    async asyncSpecificLastDoc ({ state, commit }, ownerArr) {
      const filteredArr = removeDuplicates(ownerArr, 'factory')

      const lastDoc = await getLastSpecificDocumentsApi(filteredArr)

      if (lastDoc?.data?.success) {
        const isOwner = !state.hasRoleAdmin // 業主專屬告警
        const lastDocData = lastDoc.data.data

        let sortedData = []

        ownerArr.forEach((item) => {
          if (item.isDemoSite) {
            let [useCopyObj] = lastDocData.filter(
              (data) => data.factoryName === item.factory
            )
            let insertDemo = Object.assign({}, useCopyObj)
            let displayNum = item.displaySiteName.split(' ')[1]
            insertDemo.isDemoSite = true
            insertDemo.displaySiteName = `展示案場 ${displayNum}`
            // 告警圖標 (admin / 業主)
            insertDemo.displayAlertLevel = isOwner
              ? insertDemo.ownerAlertLevel
              : insertDemo.alertLevel

            return sortedData.push(insertDemo)
          }

          if (!item.isDemoSite) {
            let [useCopyObj] = lastDocData.filter(
              (data) => data.factoryName === item.factory
            )
            let insertFactory = Object.assign({}, useCopyObj)
            insertFactory.displaySiteName = item.factory
            // 告警圖標 (admin / 業主)
            insertFactory.displayAlertLevel = isOwner
              ? insertFactory.ownerAlertLevel
              : insertFactory.alertLevel

            return sortedData.push(insertFactory)
          }
        })

        commit('ADD_FACTORY_DATA', sortedData)

        state.overlayShow = false
      }
    },
    // siteinfo 內的 top menu 每頁 若重整，需取當頁案場資料
    async asyncLastSingleDoc ({ state, commit }, siteName) {
      const isDemo =
        router.history.current.query.siteName?.includes('展示案場')
      if (isDemo) {
        const demoIndex = siteName.split(' ')[1] - 1
        siteName = state.factoryInfo[demoIndex].factory
      }
      const LastSingleDoc = await getLastDocumentApi(siteName)
      if (LastSingleDoc?.data?.success) {
        state.factoryData = [LastSingleDoc.data.data?.[0]?.detail]
        commit('LAST_MON_ACP_IRR', LastSingleDoc.data.data?.[0]?.PrRaMon)
        commit('UPDATE_DATA_ANALYSIS_STATE')
      } else {
        commit('RESET_FACTORY_DATA')
      }
    },
    async getAllGroups ({ commit }) {
      await getAllGroupsApi()
        .then(({ data }) => commit('ROLE_ARRAY', data.data))
        .catch((err) => console.log('getAllGroups', err))
    },
    async getAllAlarm ({ commit }) {
      try {
        const apiResult = await getAllAlarmsApi()
        if (apiResult?.data?.data?.length) {
          const apiRes = apiResult.data.data

          const alarmData = decryptData(apiRes)

          commit('ADD_ALARM_DATA', alarmData)
        }
      } catch (error) {
        console.log('getAllAlarm', error)
      }
    }
  },
  modules: {
    siteInfoDetail,
    deviceStatus,
    deviceHistory,
    ChartsAndBarItem,
    pvStringFailure
  }
})
